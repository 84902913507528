<template>
  <div
    class="top"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 上 -->
    <div class="protop">
      <!-- 产品名 -->
      <div class="play">
        <span>产品分类</span>
        <el-select
          v-model="valueProduct"
          clearable
          placeholder="请选择"
          @change="typeoneProduct"
          @focus="huoqu"
        >
          <el-option
            v-for="item in optionsProduct"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </div>
      <!-- 型号 -->
      <div class="antistop">
        <span class="spanguan">型号</span>
        <el-input
          placeholder
          v-model="input"
          clearable
          @change="modelType"
        ></el-input>
        <div class="buttons">
          <img
            src="../../assets/images/query.png"
            alt
            @click="searchProduct"
            style="width: 49px"
          />
        </div>
      </div>
    </div>
    <!-- <el-button round class="top-btn" @click="changePage">产品管理</el-button> -->
    <!-- 表格 -->
    <div class="table">
      <el-table :data="tableData" style="width: 100%" @row-click="rowClick">
        <el-table-column prop="id" label="ID" width="90"> </el-table-column>
        <el-table-column prop="type" label="分类" width="150">
        </el-table-column>
        <el-table-column prop="name" label="产品名称"> </el-table-column>
        <el-table-column prop="version" label="型号"> </el-table-column>
        <el-table-column prop="supplier" label="供货商名称"> </el-table-column>
        <el-table-column prop="photoUrl" label="产品图片">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleSearch(scope.$index, scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="activateMemberCn" label="是否可激活会员">
        </el-table-column>
        <el-table-column prop="addTime" label="激活会员时长(月)">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >更改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 下边 -->
    <div class="bottom">
      <div class="bottom-left">
        <el-button round class="top-btn" @click="addList">增加产品</el-button>
      </div>
      <div class="bottom-right">
        <div class="bottom-fen">
          <!-- 分页 -->
          <div class="pages">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              :current-page="pageCount"
              layout="total, prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
        <div class="buttons">
          <el-button>数据分析</el-button>
          <el-button @click="dataExport">数据导出</el-button>
        </div>
      </div>
    </div>
    <!-- 增加按钮的弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form :model="formInline" class="demo-form-inline">
        <el-form-item label="选择大类">
          <el-select
            v-model="valueProduct1"
            clearable
            placeholder="请选择"
            @change="typeoneProduct1"
            @focus="huoqu"
          >
            <el-option
              v-for="item in optionsProduct1"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品名称">
          <el-input
            v-model="formInline.name"
            placeholder="输入产品名称,产品名长度不能超过15个"
            style="width: 50%"
            @change="checkLength(formInline.name)"
          ></el-input>
        </el-form-item>
        <el-form-item label="型 号">
          <el-input
            v-model="formInline.version"
            placeholder="输入产品型号"
            style="width: 53%"
          ></el-input>
        </el-form-item>
        <el-form-item label="供货商">
          <el-input
            v-model="formInline.supplier"
            placeholder="输入供货商名称"
            style="width: 51.5%"
          ></el-input>
        </el-form-item>
        <el-form-item label="天翼AEP产品ID">
          <el-input
            v-model="formInline.productId"
            placeholder="输入天翼AEP产品ID"
            style="width: 43.5%"
            :disabled="disabled"
            @change="tianId"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否可激活会员">
          <el-select
            v-model="valueActive"
            clearable
            placeholder="请选择"
            @change="typeoneActive"
          >
            <el-option
              v-for="item in optionsActive"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="激活会员时长(月)" v-if="isAddTime">
          <el-input
            v-model="formInline.addTime"
            placeholder="输入激活会员时长"
            style="width: 50%"
            @change="changeAddTime"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品图片">
          <el-button type="primary" plain @click="pushImage"
            >上传图片</el-button
          >
          <img :src="this.imageUrl" style="width: 200px" id="addProImage" />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="onSubmit"
            style="margin-bottom: 20px"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
    <!-- 更改按钮的弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleChange"
      width="50%"
      :before-close="handleCloseChange"
    >
      <el-form :model="formInline" class="demo-form-inline">
        <el-form-item label="选择大类">
          <el-select
            v-model="valueProduct1"
            clearable
            placeholder="请选择"
            @change="typeoneProduct1"
            @focus="huoqu"
          >
            <el-option
              v-for="item in optionsProduct1"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品名称">
          <el-input
            v-model="formInline.name"
            placeholder="输入产品名称"
            style="width: 50%"
            @change="checkLength(formInline.name)"
          ></el-input>
        </el-form-item>
        <el-form-item label="型 号">
          <el-input
            v-model="formInline.version"
            placeholder="输入产品型号"
            style="width: 53%"
          ></el-input>
        </el-form-item>
        <el-form-item label="供货商">
          <el-input
            v-model="formInline.supplier"
            placeholder="输入供货商名称"
            style="width: 51.5%"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品图片">
          <el-button type="primary" plain @click="pushImage1"
            >上传图片</el-button
          >
          <img :src="this.imageUrl" style="width: 200px" id="gengaiImage" />
        </el-form-item>
        <el-form-item label="是否可激活会员">
          <el-select
            v-model="valueActive"
            clearable
            placeholder="请选择"
            @change="typeoneActive"
          >
            <el-option
              v-for="item in optionsActive"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="激活会员时长(月)" v-if="isAddTime">
          <el-input
            v-model="formInline.addTime"
            placeholder="输入激活会员时长"
            style="width: 43.5%"
            @change="changeAddTime"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="upChange">取 消</el-button>
        <el-button type="primary" @click="changeProduct">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 增加上传图片弹框 -->
    <el-dialog
      title="上传图片"
      :visible.sync="imageVisible"
      width="30%"
      :before-close="handleCloseImage"
      append-to-body
    >
      <input
        type="file"
        ref="file"
        id="tuPian"
        accept="image/*"
        @change="fileChange"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imageVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadImage">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改上传图片弹框 -->
    <el-dialog
      title="上传图片"
      :visible.sync="imageVisible1"
      width="30%"
      :before-close="handleCloseImage1"
      append-to-body
    >
      <input
        type="file"
        ref="file"
        id="tuPian1"
        accept="image/*"
        @change="fileChange1"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imageVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="uploadImage1">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 图片回显的弹框 -->
    <el-dialog
      title="产品图片"
      :visible.sync="dialogVisibleImage"
      width="30%"
      :before-close="ImagehandleClose"
    >
      <!-- <img src="http://cdn.careld.cn/datacenter/menu/product/91638329205408.png" alt=""> -->
      <el-image
        style="width: 200px; height: 200px"
        :src="productImage"
      ></el-image>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
import * as qiniu from "qiniu-js";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      baseImg: "",
      disabled: true,
      loading: true,
      nums: 15,
      names: null,
      fileList: [],
      dialogVisible: false,
      dialogVisibleChange: false,
      dialogVisibleImage: false,
      imageVisible: false,
      imageVisible1: false,
      optionsProduct: [],
      valueProduct: "",
      optionsProduct1: [],
      valueProduct1: "",
      valueActive: "",
      optionsActive: [
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      optionsAll: [],
      valueAll: "",
      optionsAll1: [],
      valueAll1: "",
      tableData: [],
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      input: "",
      //上边查询的参数
      type: "",
      name: "",
      version: "",
      parentId: "",
      //表单
      region: "",
      formInline: {
        name: "",
        version: "",
        supplier: "",
        productId: "",
        addTime: "",
      },
      photoUrl: "",
      //更改的确定
      productId: "",
      //导出数据
      exportNum: {},
      //上传图片
      token: "", //上传头像的token
      accessKey: "",
      bucket: "",
      secretKey: "",
      prefix: "",
      token1: "", //上传头像的token
      accessKey1: "",
      bucket1: "",
      secretKey1: "",
      prefix1: "",
      userinfo: {},
      id: "",
      key: "",
      imageUrl: "", //图片路径
      productImage: "", //点击查看的图片
      activeMem: "",
      flag: false,
      isAddTime: false,
      JHUY: null,
    };
  },
  created() {
    //查询产品列表
    this.productList();
    //产品列表下拉框
    this.selectproduct();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    checkLength(val) {
      this.names = val;
      console.log("val======:", val);
    },
    changeAddTime(aa) {
      console.log("激活会员时长：", aa);
      this.JHUY = aa;
    },
    onSuccess(file) {
      console.log("22222", file);
    },
    //增加点击上传图片的按钮
    pushImage() {
      this.imageVisible = true;
      //获取七牛云存储配置
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      //console.log("token", that.token);
      api.getQiuNiu({ type: "product" }).then((res) => {
        console.log("获取七牛云存储配置：", res.data.result);
        this.token = res.data.result.token;
        this.accessKey = res.data.result.accessKey;
        this.bucket = res.data.result.bucket;
        this.secretKey = res.data.result.secretKey;
        this.prefix = res.data.result.prefix;
      });
    },
    //增加确定上传图片
    uploadImage() {
      this.imageVisible = false;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //获取本地
      let bIm = JSON.parse(localStorage.getItem("backImage"));
      //清空图片
      this.fileList = [];
      // console.log("===========", this.$refs.upload.uploadFiles[0]);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      this.id = this.userinfo.id;
      if (this.$refs.file.files.length === 0) {
        alert("请选择文件");
        return;
      }
      let file = this.$refs.file.files[0];
      let shijiancuo = new Date().getTime();
      let token = this.token;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefix + this.id + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("productPhotoAdd", JSON.stringify(res.key));
          console.log("this:", this);
          this.imageUrl = "http://cdn.careld.cn/" + res.key;
          loading.close();
          document.getElementById("addProImage").src = bIm;
          // document.getElementById("gengaiImage").src=bIm;
          // alert('上传成功');
          //存本地
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    //增加上传头像格式限制
    fileChange(e) {
      console.log("-----------1111:", e.target.files);
      //格式限制
      // var fileName =e.target.files.name.lastIndexOf(".") + 1;
      // if (fileName != "jpg" && fileName != "png") {
      //   alert("请选择jpg或者png格式文件上传！");
      //   return false; //阻止submit提交
      // }
      // console.log('ref:',this.$refs.file.files);

      var file = e.target.files[0];
      if (window.FileReader) {
        debugger;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        //监听文件读取结束后事件
        reader.onloadend = function (e) {
          //  console.log('地址路径',e.target.result);   //e.target.result就是最后的路径地址
          //  document.getElementById("gengaiImage").src=e.target.result
          //保存本地
          localStorage.setItem("backImage", JSON.stringify(e.target.result));
          //  this.baseImg=e.target.result;
        };
      }

      //大小限制
      var filemaxsize = 1024 * 2; //2M
      var size = file.size / 1024;
      if (size > filemaxsize) {
        alert("附件大小不能大于" + filemaxsize / 1024 + "M！");
        return false;
      }
      if (size <= 0) {
        alert("附件大小不能为0M！");
        return false;
      }
      // if (e.target.files.name) {
      //   console.log('00000执行到这了吗');
      //   if (e.target.files[0].size / 1024 < 20) {
      //     this.$message.error("上传文件大小不能超过 20MB!");
      //     return false;
      //   } else if (e.target.files[0].size <= 0) {
      //     alert("文件大小不能为0M！");
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
    },
    //更改点击上传图片的按钮
    pushImage1() {
      this.imageVisible1 = true;
      //获取七牛云存储配置
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      //console.log("token", that.token);
      api.getQiuNiu({ type: "product" }).then((res) => {
        console.log("获取七牛云存储配置：", res.data.result);
        this.token1 = res.data.result.token;
        this.accessKey1 = res.data.result.accessKey;
        this.bucket1 = res.data.result.bucket;
        this.secretKey1 = res.data.result.secretKey;
        this.prefix1 = res.data.result.prefix;
      });
    },
    //更改确定上传图片
    uploadImage1() {
      this.imageVisible1 = false;
      this.flag = true;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //获取本地
      let bIm = JSON.parse(localStorage.getItem("backImage1"));
      //清空图片
      this.fileList = [];
      // console.log("===========", this.$refs.upload.uploadFiles[0]);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      this.id = this.userinfo.id;
      if (this.$refs.file.files.length === 0) {
        alert("请选择文件");
        return;
      }
      let file = this.$refs.file.files[0];
      let shijiancuo = new Date().getTime();
      let token1 = this.token1;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefix1 + this.id + shijiancuo + ming,
        token1,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("productPhoto", JSON.stringify(res.key));
          console.log("this:", this);
          this.imageUrl = "http://cdn.careld.cn/" + res.key;
          loading.close();
          // document.getElementById("addProImage").src=bIm;
          document.getElementById("gengaiImage").src = bIm;
          // alert('上传成功');
          //存本地
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    //更改上传头像格式限制
    fileChange1(e) {
      console.log("-----------1111:", e.target.files);
      //格式限制
      // var fileName =e.target.files.name.lastIndexOf(".") + 1;
      // if (fileName != "jpg" && fileName != "png") {
      //   alert("请选择jpg或者png格式文件上传！");
      //   return false; //阻止submit提交
      // }
      // console.log('ref:',this.$refs.file.files);

      var file = e.target.files[0];
      if (window.FileReader) {
        debugger;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        //监听文件读取结束后事件
        reader.onloadend = function (e) {
          //  console.log('地址路径',e.target.result);   //e.target.result就是最后的路径地址
          //  document.getElementById("gengaiImage").src=e.target.result
          //保存本地
          localStorage.setItem("backImage1", JSON.stringify(e.target.result));
          //  this.baseImg=e.target.result;
        };
      }

      //大小限制
      var filemaxsize = 1024 * 2; //2M
      var size = file.size / 1024;
      if (size > filemaxsize) {
        alert("附件大小不能大于" + filemaxsize / 1024 + "M！");
        return false;
      }
      if (size <= 0) {
        alert("附件大小不能为0M！");
        return false;
      }
      // if (e.target.files.name) {
      //   console.log('00000执行到这了吗');
      //   if (e.target.files[0].size / 1024 < 20) {
      //     this.$message.error("上传文件大小不能超过 20MB!");
      //     return false;
      //   } else if (e.target.files[0].size <= 0) {
      //     alert("文件大小不能为0M！");
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
    },

    //查看
    handleSearch(index, row) {
      console.log(index, row);
      this.dialogVisibleImage = true;
      this.productImage = row.photoUrl;
    },
    //获取焦点时再请求一次
    huoqu() {
      api.getSelectProduct({ dictCode: "producttype" }).then((res) => {
        //console.log("下拉框：", res.data);
        if (res.data.code == 200) {
          this.optionsProduct = res.data.result;
          this.optionsProduct1 = res.data.result;
        }
      });
    },
    typeoneProduct(value) {
      //console.log('type:',value);
      this.type = value;
      api.getSelectProduct({ dictCode: "producttype" }).then((res) => {
        //console.log('下拉2：',res.data);
        if (res.data.code == 200) {
          this.optionsAll = res.data.result;
        }
      });
    },
    typeoneProduct1(value) {
      console.log("region:", value);
      this.region = value;
      api.getSelectProduct({ dictCode: "producttype" }).then((res) => {
        //console.log('下拉2：',res.data);
        if (res.data.code == 200) {
          this.optionsAll1 = res.data.result;
        }
      });
      if (this.region == "2" || this.region == "3") {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    tianId(val) {
      console.log("天翼id:", val);
    },
    typeoneActive(aa) {
      console.log("选中：", aa);
      this.activeMem = aa;
      if (aa == "1") {
        this.isAddTime = true;
      } else {
        this.isAddTime = false;
      }
    },
    typetwoProduct(val) {
      //console.log('name:',val);
      this.name = val;
    },
    modelType(value1) {
      // console.log('类型：',value1);
      this.version = value1;
    },
    //上边的查询
    searchProduct() {
      this.productList();
      //清空查询的框
      // this.input = "";
      // this.valueProduct = "";
      // this.valueAll = "";
    },
    //增加产品信息
    addList() {
      this.dialogVisible = true;
      this.huoqu();
      if (document.getElementById("tuPian") != null) {
        document.getElementById("tuPian").value = "";
      }
    },
    //增加的表单
    onSubmit() {
      this.dialogVisible = false;
      if (this.nums < this.formInline.name.length) {
        this.$message.error("产品名称过长无法提交");
        return false;
      }
      if (this.isAddTime == true) {
        if (!/^\+?[1-9][0-9]*$/.test(this.formInline.addTime)) {
          this.$message.error("提交失败，激活会员时长只能为正整数且不能为空");
          return false;
        }
      }
      if (this.disabled == false) {
        if (
          this.formInline.productId == "" ||
          this.formInline.productId == null
        ) {
          this.$message.error("提交失败，天翼AEP产品ID不能为空");
          return false;
        }
      }
      // if(aa==null||aa==""){
      //    this.$message.error("激活会员时长不能为空");
      // }
      //获取本地
      let proPh = JSON.parse(localStorage.getItem("productPhotoAdd"));
      this.photoUrl = proPh;
      // console.log('=========lalalal:',this.photoUrl);
      let obj = {
        type: this.region,
        name: this.formInline.name,
        version: this.formInline.version,
        supplier: this.formInline.supplier,
        productId: this.formInline.productId,
        photoUrl: this.photoUrl,
        activateMember: this.activeMem,
        addTime: this.formInline.addTime,
      };
      console.log("this.formInline.addTime:", this.formInline.addTime);
      apiShout.getAddProduct(obj).then((res) => {
        console.log("增加产品信息：", res.data);
        if (res.data.code == 200) {
          this.$message({
            message: "恭喜你,成功增加产品信息",
            type: "success",
          });
          //刷新页面
          this.productList();
          //清空表单
          this.region = "";
          this.photoUrl = "";
          this.valueProduct1 = "";
          this.imageUrl = "";
          this.valueActive = "";
          document.getElementById("addProImage").src = "";
          this.formInline = {
            name: "",
            version: "",
            supplier: "",
            productId: "",
            addTime: "",
          };
          this.disabled = true;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //查询产品列表
    productList() {
      apiShout
        .getProduct({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          type: this.type,
          name: this.name,
          version: this.version,
        })
        .then((res) => {
          console.log("产品列表：", res.data);
          if (res.data.code == 200) {
            // for(let i=0;i<res.data.result.data.length;i++){
            //   if(res.data.result.data[i].addTime!=""){
            //     res.data.result.data[i].addTime=res.data.result.data[i].addTime+'(月)';
            //   }
            // }
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //产品列表下拉框
    selectproduct() {
      api.getSelectProduct({ dictCode: "producttype" }).then((res) => {
        console.log("产品列表下拉框：", res.data);
        if (res.data.code == 200) {
          this.parentId = res.data.result[0].id;
        }
      });
    },
    //更改
    handleEdit(index, row) {
      console.log(index, row);
      this.dialogVisibleChange = true;
      this.productId = row.id;
      //查询产品详情
      apiShout.getDetailProduct({ id: row.id }).then((res) => {
        console.log("查询产品详情：", res.data);
        if (res.data.code == 200) {
          this.formInline = res.data.result;
          this.valueProduct1 = row.type;
          this.valueAll1 = row.name;
          this.imageUrl = res.data.result.photoUrl;
          this.valueActive = row.activateMemberCn;
          if (this.valueActive == "是") {
            this.isAddTime = true;
          } else {
            this.isAddTime = false;
          }
          this.formInline.addTime = row.addTime;
        }
      });
    },
    //更改的确定
    changeProduct() {
      this.dialogVisibleChange = false;
      if (
        this.nums < this.formInline.name.length ||
        this.nums < this.formInline.name.length
      ) {
        this.$message.error("产品名称过长无法提交");
        return false;
      }
      if (this.isAddTime == true) {
        if (!/^\+?[1-9][0-9]*$/.test(this.formInline.addTime)) {
          this.$message.error("提交失败，激活会员时长只能为正整数且不能为空");
          return false;
        }
      }
      //获取本地
      let proPh = JSON.parse(localStorage.getItem("productPhoto"));
      if (this.flag == true) {
        this.photoUrl = "http://cdn.careld.cn/" + proPh;
      } else {
        this.photoUrl = this.imageUrl;
      }

      // this.imageUrl=proPh;
      //清除本地
      let obj = {
        id: this.productId,
        type: this.region,
        name: this.formInline.name,
        version: this.formInline.version,
        supplier: this.formInline.supplier,
        photoUrl: this.photoUrl,
        activateMember: this.activeMem,
        addTime: this.formInline.addTime,
      };
      apiShout.getChangeProduct(obj).then((res) => {
        //console.log('修改产品信息：',res.data);
        if (res.data.code == 200) {
          this.$message({
            message: "恭喜你,修改产品信息成功",
            type: "success",
          });
          //刷新页面
          this.productList();
          this.flag = false;
          //清空列表
          //清空表单
          this.region = "";
          this.valueProduct1 = "";
          this.valueAll1 = "";
          this.imageUrl = "";
          this.valueActive = "";
          //清除本地
          localStorage.removeItem("backImage");
          this.formInline = {
            productCode: "",
            name: "",
            version: "",
            supplier: "",
          };
        }
      });
      document.getElementById("tuPian1").value = "";
    },
    //每页的条数分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.productList();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.productList();
    },
    handleClose() {
      this.dialogVisible = false;
      //清空表单
      this.region = "";
      this.photoUrl = "";
      this.valueProduct1 = "";
      this.imageUrl = "";
      this.valueActive = "";
      document.getElementById("addProImage").src = "";
      this.formInline = {
        name: "",
        version: "",
        supplier: "",
        productId: "",
        addTime: "",
      };
    },
    handleCloseChange() {
      this.dialogVisibleChange = false;
      //清空表单
      this.region = "";
      this.valueProduct1 = "";
      this.valueAll1 = "";
      this.imageUrl = "";
      this.valueActive = "";
      this.formInline = {
        productCode: "",
        name: "",
        version: "",
        supplier: "",
      };
      document.getElementById("tuPian1").value = "";
    },
    handleCloseImage() {
      this.imageVisible = false;
    },
    handleCloseImage1() {
      this.imageVisible1 = false;
    },
    ImagehandleClose() {
      this.dialogVisibleImage = false;
    },
    upChange() {
      this.dialogVisibleChange = false;
      this.region = "";
      this.photoUrl = "";
      this.valueProduct1 = "";
      this.imageUrl = "";
      this.valueActive = "";
      // document.getElementById("addProImage").src = "";
      this.formInline = {
        name: "",
        version: "",
        supplier: "",
        productId: "",
        addTime: "",
      };
    },
    //数据导出
    dataExport() {
      // let obj=this.exportNum;
      // apiShout.getExportProduct(obj).then(res=>{
      //   console.log('数据导出：',res.data);
      // })
    },
    //点击表格事件
    rowClick(row) {
      console.log("row,column,event:", row);
      this.exportNum = row;
    },
    // //点击按钮切换
    // changePage(){
    //   // this.$router.push('/system_maintain/terminal');
    // },
  },
};
</script>

<style lang='less' scoped>
.top {
  overflow-x: hidden;
}
//上边
.protop {
  margin-top: 20px;
  margin-left: 25px;
  height: auto;
  margin-right: 40px;
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    color: #909399;
    margin-right: 10px;
    font-weight: 700;
  }
  // input框
  .el-input__inner {
    border-radius: 0;
    height: 33px;
    line-height: 33px;
  }
  .el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 33px;
  }
  // 播放次数
  .play {
    left: 20px;
    top: 3px;
  }
  // 关键词
  .antistop {
    left: 700px;
    top: 3px;
    display: flex;
    align-items: center;
    .spanguan {
      margin-left: 80px;
    }
    .el-input {
      width: 230px;
    }
    .buttons {
      margin-left: 14px;
    }
  }
}
// 下边
.bottom-right {
  float: right;
  text-align: right;
}
// 按钮
.buttons {
  display: flex;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    margin-right: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}

.top-btn {
  background: #d78673;
  margin-left: 30px;
  color: #ffffff;
}
.bottom-fen {
  margin-top: 10px;
}
.bottom-left {
  float: left;
}

.healLabel {
  width: 400px;
  height: 50px;
  background-color: #ebe9e9;
  line-height: 50px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
  letter-spacing: 1px;
}
.offen {
  margin-left: 150px;
}
//表格
.table {
  margin: 20px 40px 20px 25px;
  width: auto !important;
  /deep/ .el-table {
    td,
    th.is-leaf {
      border: none !important;
    }
    tr th {
      font-size: 14px;
      color: #909399;
      text-align: center;
    }
    tr td {
      text-align: center;
      font-size: 14px;
      color: #606266;
    }
  }
}
//弹框
/deep/ .el-dialog__body {
  margin: 20px;
}
/deep/.el-input__inner {
  border-radius: 0;
  height: 33px;
  line-height: 33px;
}
/deep/.el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 28px;
}
/deep/.el-form-item__label {
  color: #909399;
}

/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>